import {Box, Button, Typography} from "@mui/material";
import styles from '../../styles/TextWithSideImage.module.scss'
import PageContainer from "../layouts/PageContainer";
import Image from "next/image";
import Link from 'next/link';

export default function TextWithSideImage({
                                              textDirection,
                                              primaryText,
                                              text,
                                              secondaryText,
                                              image,
                                              color = "white",
                                              imageWidth,
                                              imageAlt,
                                              imageHeight,
                                              readMoreUrl // Assume this is the prop where the link URL will be passed
                                          }) {
    return (
        <Box className={styles.container}>
            <PageContainer>
                <Box className={styles.innerContainer} style={{ flexDirection: (textDirection === 'left' ? 'row' : 'row-reverse') }}>
                    <Box className={styles.text}>
                        <Typography component="h2" className={styles.primaryText}>
                            {primaryText}
                        </Typography>
                        <Typography component="div" className={styles.contentText}>
                            {text}
                        </Typography>

                        {/* Wrap the button with the Link component */}
                        <Link href={readMoreUrl} passHref>
                            <Button className={styles.btn}>Read More</Button>
                        </Link>
                    </Box>
                    <Box className={styles.image}>
                        <Image priority={true} src={image} width={imageWidth} height={imageHeight} alt={imageAlt || primaryText} />
                    </Box>
                </Box>
            </PageContainer>
        </Box>
    )
}
import styles from '../../styles/MidContentSection.module.scss';
import React from "react";
import {Box} from "@mui/material"; // Make sure to create this CSS module file

const MidContentSection = ({ children }) => {
    return (
        <Box className={styles.midContentSection}>
            {children}
        </Box>
    );
};

export default MidContentSection;

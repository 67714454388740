import styles from '../styles/Home.module.css'
import {Box, Typography} from "@mui/material";
import TextWithSideImage from "../components/shared/TextWithSideImage";
import HomeIntro from "../components/sections/HomeIntro";
import Testimonials from "../components/shared/Testimonials";
import Head from "next/head";
import seo from "../constants/seo.json";
import MidContentSection from "../components/sections/MidContentSection";

export default function Home() {
    const meta = seo.home;

    return (
        <div className={styles.container}>

            <Head>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description}/>
                <meta name="keywords" content={meta.keywords}/>
            </Head>

            <Box className={styles.bigBlueBackground}/>

            <HomeIntro/>

            <Box sx={{mt: "10rem", height: "600px", mb: "10rem"}}>
                <TextWithSideImage textDirection={'right'}
                                   primaryText={'About Hoot-n-Holler Networks'}
                                   text={'We provide IP-Based Hoot-n-Holler services for diverse clientele including Wall Street traders, Energy Traders, Auto Parts Recyclers, and various companies seeking advanced alternatives to traditional leased line based Hoot-n-Holler.'}
                                   image={'/images/features/about-hoot-holler.png'}
                                   imageAlt={'About Hoot-n-Holler Networks'}
                                   readMoreUrl={'/about'}
                                   imageWidth={544}
                                   imageHeight={526}
                />
            </Box>


            <MidContentSection>
                <TextWithSideImage textDirection={'right'}
                                   primaryText={'Wall Street Traders'}
                                   text={'No dial in is required to join a hoot conference. Traders can switch between hoot rooms simply by depressing different buttons on their Turrets. Enables quick conversation between a group of traders.'}
                                   image={'/images/wallstreet-traders.webp'}
                                   imageAlt={'Fire Chiefs emergency conferencing'}
                                   readMoreUrl={'/service/wall-street-traders'}
                                   imageWidth={486}
                                   imageHeight={372}
                />

                <TextWithSideImage textDirection={'left'}
                                   primaryText={'Energy Traders'}
                                   text={'Energy traders can harness hoot and holler systems to instantly share market fluctuations and energy supply updates, facilitating real-time strategy adjustments across the trading floor for optimal response to market demands.'}
                                   image={'/images/features/energy-traders.png'}
                                   imageAlt={'Paramedics emergency call'}
                                   readMoreUrl={'/service/energy-traders'}
                                   imageWidth={486}
                                   imageHeight={372}
                />

                <TextWithSideImage textDirection={'right'}
                                   primaryText={'Auto Parts Traders'}
                                   text={'Hoot and holler systems streamline communication for auto parts traders, enabling instant broadcasts of inventory changes and availability of parts to all trader locations, fostering rapid, informed decision-making.'}
                                   image={'/images/features/auto-parts-traders.png'}
                                   imageAlt={'Power Plant Emergency strategy'}
                                      readMoreUrl={'/service/auto-parts-traders'}
                                   imageWidth={486}
                                   imageHeight={372}
                />

                <TextWithSideImage textDirection={'left'}
                                   primaryText={'Transport Vertical'}
                                   text={'Transport operators in air and rail can leverage hoot and holler systems for immediate coordination and updates on logistics, ensuring synchronized operations and rapid response to any changes in schedules or routes.'}
                                   image={'/images/features/transport-vertical.png'}
                                   imageAlt={'Electricians emergency'}
                                   readMoreUrl={'/service/transport-vertical'}
                                   imageWidth={486}
                                   imageHeight={372}
                />
            </MidContentSection>

            <Testimonials/>


        </div>
    )
}

import {Box, Button, Slide, Typography, useTheme} from "@mui/material";
import PageContainer from "../layouts/PageContainer";
import styles from '../../styles/Home.module.css'
import Link from "next/link";
import Image from "next/image";
import {useIntl} from "react-intl";

export default function HomeIntro() {
    const intl = useIntl();
    const theme = useTheme();

    return (
        <Box className={styles.headerInto}>
            <PageContainer>

                <Box className={styles.headerInfoInner}>
                    {/*<Slide direction={"right"} easing={theme.transitions.easing.easeInOut} timeout={700}>*/}
                        <Box className={styles.headerInfoLeft}>
                            <Typography className={styles.mainText} variant={"h1"}>
                                IP based Hoot and Holler Service
                            </Typography>
                            <Typography className={styles.mainTextDescription} variant={"h2"}>
                                Unleash real-time collaboration with our premier hoot and holler systems. Your network, amplified.
                            </Typography>

                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                <Button variant={"outlined"} component={Link} href={'/service/how-it-works'} title={'Features list for product'}
                                        className={styles.learnMoreBtn}>
                                    Read More
                                </Button>
                            </Box>
                        </Box>
                    {/*</Slide>*/}
                    <Box className={styles.headerInfoRight}>
                        <Image priority={true} src={'/images/flow-animation-image.png'} width={868} height={733}
                               alt={"Hoot Holler VOIP communication provider"}/>
                    </Box>
                </Box>

            </PageContainer>
        </Box>
    )
}

